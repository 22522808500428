import React, { useEffect, useState } from "react";
import styles from "./PromoCategories.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../UI/Buttons/Button/Button";
import { RootState } from "../../../store";
import TextField from "../../TextField/TextField";
import { fetchItemNames } from "../../../store/rfzoTherapy-actions";
import SearchBarMultiple from "../../UI/Modals/RFZOTherapyRequestModal/SearchWrapper/SearchBarMultiple/SearchBarMultiple";
import axios from "../../../axios/axios";
import { baseUrl } from "../../../axios/axios";
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import editIcon from "../../../assets/icons/edit.svg";
import deleteIcon from "../../../assets/icons/trash.svg";
import priorityCategoryIcon from "../../../assets/icons/priorityCategory.svg";
import classNames from "classnames";
import NotificationMessage from "../../UI/NotificationMessage/NotificationMessage";
import Select from "react-select";
import MediaUploadWrap from "../../MediaUploadWrap/MediaUploadWrap";

const Row = ({ children, style }: any) => {
  return <div style={{ display: "flex", alignItems: "center", gap: 5, ...style }}>{children}</div>;
};

const PromoCategories = () => {
  const [allCategories, setAllCategories] = useState([]);

  const fetchCategories = () => {
    getAllCategories().then((res) => setAllCategories(res.data));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [editCatData, setEditCatData] = useState();

  const editCategory = (cat: any) => {
    setEditCatData(cat);
  };

  return (
    <div className={styles.categoriesWrap}>
      <div className={styles.header}>Nove kategorije</div>

      <SingleCategory initialData={editCatData} refetch={fetchCategories} />
      <CategoriesList
        editCategory={editCategory}
        refetch={fetchCategories}
        setAllCategories={setAllCategories}
        allCategories={allCategories}
      />
    </div>
  );
};

export default PromoCategories;

const SingleCategory = ({ initialData, onCancel, refetch }: any) => {
  const dispatch = useDispatch();

  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);

  const successfulNotification = () => {
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  useEffect(() => {
    const initFilesData =
      initialData?.files?.map((file: any) => ({
        ...file,
        imageLink: process.env.REACT_APP_CDN_PATH + "item_group_images/" + file?.imageLink,
      })) || [];

    console.log(initFilesData);
    setImageList(initFilesData);
    setInitImageList(initialData?.files);
    setSelectedItems(initialData?.items?.map((i: any) => i) || []);
    setCategoryData(initialData);
  }, [initialData]);

  const itemNames = useSelector((state: RootState) => state.rfzoTherapy.itemNames);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryData, setCategoryData] = useState<any>({});
  const [imageList, setImageList] = useState([]);
  const [initImageList, setInitImageList] = useState([]);

  const cancelWrap = () => {
    if (initialData) {
      onCancel();
    } else {
      setSelectedItems([]);
      setCategoryData({});
      setImageList([]);
      setInitImageList([]);
    }
  };

  useEffect(() => {
    dispatch<any>(fetchItemNames());
  }, []);

  const createCategoryWrap = () => {
    let repackedMediaArray = imageList.map((media: any) => {
      if (media instanceof File) {
        return {
          imageLink: media?.name,
          priority: 1,
          isSmall: 0,
          action: "ADD",
          type: media?.type?.toLowerCase()?.includes("image") ? "IMAGE" : "VIDEO",
        };
      } else {
        return null;
      }
    });

    if (!initialData) {
      console.log(imageList);
      createCategory({ ...categoryData, items: selectedItems.map((item: any) => item.id) }, imageList).then((res) => {
        successfulNotification();
        cancelWrap();
        refetch();
      });
    } else {
      const initialItemsList = initialData?.items || [];
      const addedItemsList = selectedItems
        ?.filter((itemB: any) => !initialItemsList?.some((itemA: any) => itemA.id === itemB.id))
        ?.map((item: any) => ({ itemId: item.id, action: "ADD" }));
      const deletedItemsList = initialItemsList
        ?.filter((itemA: any) => !selectedItems.some((itemB: any) => itemB.id === itemA.id))
        .map((item: any) => ({ itemId: item.id, action: "REMOVE" }));

      const itemRequests = [...addedItemsList, ...deletedItemsList];

      const idsInImageList = new Set(
        imageList.filter((item: any) => item.id !== undefined).map((item: any) => item.id)
      );
      let missingImages = initImageList.filter((item: any) => !idsInImageList.has(item.id)) || [];
      const missingImagesWrap = missingImages.map((item: any) => ({ ...item, action: "REMOVE" }));
      repackedMediaArray = [...repackedMediaArray, ...missingImagesWrap].filter(Boolean);
      const filesList = imageList.filter((item: any) => item instanceof File);

      updateCategory(
        { ...categoryData, items: selectedItems.map((item: any) => item.id) },
        repackedMediaArray,
        initialData.id,
        itemRequests,
        filesList
      ).then((res) => {
        successfulNotification();
        cancelWrap();
        refetch();
      });
    }
  };

  const removeItemFromList = (itemId: any) => {
    setSelectedItems((prevItems) => prevItems.filter((item: any) => item.id !== itemId));
  };

  return (
    <div>
      {notificationMessageVisible && <NotificationMessage>Uspesno</NotificationMessage>}

      <div className={styles.subHeader}>{initialData ? "Izmeni" : "Dodaj"} standardnu kategoriju</div>
      <TextField
        label="Naziv kategorije"
        value={categoryData?.name || ""}
        placeHolder="Unsite naziv kategorije..."
        onChange={(val: any) => {
          setCategoryData((prev: any) => ({ ...prev, name: val }));
        }}
      />
      <MediaUploadWrap
        label="Naslovna slika kategorije"
        initImageList={initImageList}
        imageList={imageList}
        setImageList={setImageList}
      />
      <ItemPickingModal
        selectedItemsInitial={selectedItems}
        onConfirm={(val: any) => setSelectedItems(val)}
        itemNames={itemNames}
      />
      {selectedItems.length > 0 &&
        selectedItems?.map((item: any) => (
          <div style={{ marginBottom: "5px", display: "flex", alignItems: "center" }}>
            <span>{item?.name}</span>
            <button
              onClick={() => {
                removeItemFromList(item.id);
              }}>
              <img src={deleteIcon} />
            </button>
          </div>
        ))}
      <Row style={{ marginTop: "20px" }}>
        <Button onClick={() => cancelWrap()} buttonType="white">
          Odustani
        </Button>
        <Button disabled={!categoryData?.name} onClick={() => createCategoryWrap()} buttonType="blue">
          Sacuvaj
        </Button>
      </Row>
    </div>
  );
};
const CategoriesList = ({ allCategories, refetch, editCategory }: any) => {
  const [priorityCategory, setPriorityCategory] = useState<any>(null);

  console.log(priorityCategory);

  useEffect(() => {
    const pCat = allCategories?.find((cat: any) => cat?.priority === true);
    if (pCat) {
      setPriorityCategory({ label: pCat?.name, value: pCat?.id });
    }
  }, [allCategories]);

  if (allCategories.length === 0) {
    return <div className={styles.noCategories}>Trenutno nema kategorija</div>;
  }

  const categoriesForPriorityPick = allCategories?.map((cat: any) => ({
    value: cat?.id,
    label: cat?.name,
  }));

  return (
    <div className={styles.categoriesListWrap}>
      <div className={styles.subHeader}>Izaberite prioritetnu kategoriju</div>

      <Select
        options={categoriesForPriorityPick}
        onChange={(val) => {
          setPriorityCategory(val);
          updateCategoryPriority(val);
        }}
        value={{ label: priorityCategory?.label, value: priorityCategory?.value }}
      />

      <div className={styles.subHeader}>Lista kategorija</div>
      <div>
        {allCategories?.map((cat: any) => (
          <CategoryItem
            priorityCategory={priorityCategory}
            refetch={refetch}
            cat={cat}
            editCategory={editCategory}
            onDelete={async () => {
              await deleteCategory(cat?.id);
              refetch();
            }}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryItem = ({ cat, onDelete, refetch, priorityCategory }: any) => {
  const [editCategory, setEditCategory] = useState(false);

  return (
    <div
      className={classNames(styles.categoryItemWrap, {
        [styles.editMode]: editCategory,
      })}>
      <div className={styles.category}>
        <div className={styles.categoryName}>
          {priorityCategory?.value === cat?.id && <img style={{ marginRight: "5px" }} src={priorityCategoryIcon} />}
          {cat?.name}
        </div>
        <div>
          <img alt="icon" onClick={() => setEditCategory(true)} src={editIcon} />
          <img alt="icon" onClick={onDelete} src={deleteIcon} />
        </div>
      </div>
      {editCategory && <SingleCategory refetch={refetch} onCancel={() => setEditCategory(false)} initialData={cat} />}
    </div>
  );
};

const ItemPickingModal = ({ itemNames, onConfirm, selectedItemsInitial }: any) => {
  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <Button className={styles.addItemsButton} buttonType="white" onClick={() => setShow(true)}>
        Izaberi proizvode
      </Button>
    );
  }

  return (
    <SimpleModal backdropClickHandler={() => setShow(false)} show={show} className={styles.Modal}>
      <SearchBarMultiple
        selectedItemsInitial={selectedItemsInitial}
        onConfirm={(val: any) => {
          onConfirm(val);
          setShow(false);
        }}
        data={itemNames}
      />
    </SimpleModal>
  );
};

const createCategory = (data: any, imageList: any) => {
  const branchMarketplaceId = JSON.parse(localStorage.getItem("branchMarketplaceId") || "");
  const marketplaceId = JSON.parse(localStorage.getItem("marketplaceId") || "");

  console.log(imageList);

  const imagesNames = imageList.map((img: any) => {
    return {
      imageLink: img?.name,
      priority: 1,
      isSmall: 0,
      action: "ADD",
      type: img?.type?.toLowerCase()?.includes("image") ? "IMAGE" : "VIDEO",
    };
  });

  const formData = new FormData();

  const payload = {
    marketplaceId: {
      marketplaceId: marketplaceId,
      branchMarketplaceId: branchMarketplaceId,
    },
    name: data.name,
    itemRequests: data.items?.map((item: any) => ({
      itemId: item,
      action: "ADD",
    })),
    imageRequests: imagesNames,
  };

  const blobData = new Blob([JSON.stringify(payload)], {
    type: "application/json",
  });

  formData.append("groupRequest", blobData);
  imageList.forEach((i: any) => formData.append("imageFiles", i));

  return axios.post(baseUrl + `/related_group/custom`, formData);
};
const updateCategory = (data: any, imageList: any, groupId: any, itemsRequest: any[], files: any[]) => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  const formData = new FormData();
  const payload = {
    groupId: groupId,
    marketplaceId: {
      marketplaceId: marketplaceId,
      branchMarketplaceId: branchMarketplaceId,
    },
    name: data.name,
    itemRequests: itemsRequest,
    imageRequests: imageList,
  };
  const blobData = new Blob([JSON.stringify(payload)], {
    type: "application/json",
  });

  formData.append("groupRequest", blobData);
  files.forEach((i: any) => formData.append("imageFiles", i));
  return axios.put(baseUrl + `/related_group/custom`, formData);
};

const getAllCategories = () => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  return axios.get(
    baseUrl +
      `/related_group/custom?marketplaceId=${marketplaceId}&branchMarketplaceId=${branchMarketplaceId}&fetchItems=true`
  );
};
const updateCategoryPriority = (category: any) => {
  const groupId = category?.value;
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");

  return axios.put(
    baseUrl +
      `/related_group/custom/${groupId}/update_priority?marketplaceId=${marketplaceId}&branchMarketplaceId=${branchMarketplaceId}`
  );
};

const deleteCategory = (id: any) => {
  const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
  const marketplaceId = localStorage.getItem("marketplaceId");
  return axios.delete(
    baseUrl + `/related_group/custom/${id}?marketplaceId=${marketplaceId}&branchMarketplaceId=${branchMarketplaceId}`
  );
};
