import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { statusActions } from "../../../store/status-slice";
import styles from "./PharmacyImagesAndStatus.module.scss";
import PharmaciesImagesModal from "../PharmacieImagesModal/PharmaciesImagesModal";
import axios, { baseUrl } from "../../../axios/axios";
import Button from "../../UI/Buttons/Button/Button";
import { fetchMarketPlaceDetails } from "../StatusComponent";
import { fetchMarketplaceInfo } from "../../../store/auth-actions";

const PharmacyImagesAndStatus = ({
  marketplaceInfoDetailed,
  marketplaceInfo,
  statusToggle,
  logout,
  setMarketplaceInfoDetailed,
}: any) => {
  const dispatch = useDispatch();
  const showStatusScreen = () => {
    dispatch(statusActions.toggleStatusScreen(false));
  };
  const [activeButtonTitle, setActiveButtonTitle] = useState("Status: aktivna");

  useEffect(() => {
    if (marketplaceInfo && marketplaceInfo?.takesOrders === 1) {
      setActiveButtonTitle("Status: aktivna");
    } else {
      setActiveButtonTitle("Status: neaktivna");
    }
  }, [marketplaceInfo]);
  useEffect(() => {
    if (marketplaceInfo && marketplaceInfo?.takesOrders === 1) {
      setActiveButtonTitle("Status: aktivna");
    } else {
      setActiveButtonTitle("Status: neaktivna");
    }
  }, [marketplaceInfo]);

  console.log("refresh", marketplaceInfoDetailed);

  const imageSrc =
    marketplaceInfoDetailed?.pictures?.length > 0
      ? process.env.REACT_APP_CDN_PATH + `marketplace_images/${marketplaceInfoDetailed?.pictures[0]}`
      : "";

  const [show, setShow] = useState(false);

  return (
    <div className={styles.pharmacyImagesAndStatus}>
      <div className={styles.coverImage}>
        <PharmaciesImagesModal
          show={show}
          setShow={setShow}
          submitImageChange={(imageList: any, initImageList: any) => {
            updateImages(marketplaceInfoDetailed, imageList, initImageList).then(() => {
              setShow(false);
              const branchMarketplaceId = localStorage.getItem("branchMarketplaceId");
              const marketplaceId = localStorage.getItem("marketplaceId");
              fetchMarketPlaceDetails(branchMarketplaceId, marketplaceId).then((res) =>
                setMarketplaceInfoDetailed(res.data)
              );
              dispatch<any>(fetchMarketplaceInfo(parseInt(localStorage.getItem("id")!)));
            });
          }}
          currentImages={marketplaceInfoDetailed?.files}
        />
        <img className={styles.image} alt="img" src={imageSrc} width={160} height={160} />
      </div>
      <div className={styles.pharmacyName}>{marketplaceInfoDetailed && marketplaceInfoDetailed.name}</div>
      <div className={styles.pharmacyStatus}>
        <div className={styles.buttons}>
          <Button
            className={styles.actionButton}
            buttonType={marketplaceInfo.takesOrders === 1 ? "blue" : "red"}
            onClick={statusToggle}>
            {activeButtonTitle}
          </Button>
          <Button className={styles.actionButton} onClick={() => showStatusScreen()} buttonType="blue">
            Pregled Porudzbina
          </Button>
        </div>
      </div>
      <Button className={styles.logoutButton} onClick={logout}>
        Odjavi se
      </Button>
    </div>
  );
};

export default PharmacyImagesAndStatus;

const updateImages = (data: any, imageList: any, initImageList: any) => {
  let initItemNames = initImageList || [];

  const imagesNames = imageList.map((img: any) => {
    return img?.file?.name;
  });
  initItemNames = [...initItemNames, ...imagesNames];
  const formData = new FormData();
  const payload = {
    ...data,
  };

  let repackedMediaArray = imageList.map((media: any) => {
    if (media instanceof File) {
      return {
        imageLink: media?.name,
        priority: 1,
        isSmall: 0,
        type: media?.type?.toLowerCase()?.includes("image") ? "IMAGE" : "VIDEO",
      };
    } else {
      const imageLinkDraft = media.imageLink.split("/marketplace_images/")[1];
      return {
        type: media.type,
        priority: media.priority,
        imageLink: imageLinkDraft,
        isSmall: media.isSmall,
      };
    }
  });
  repackedMediaArray = [...repackedMediaArray].filter(Boolean);
  const filesList = imageList.filter((item: any) => item instanceof File);

  payload.pictures = repackedMediaArray;
  payload.files = repackedMediaArray;

  formData.append("marketplaceRequestString", JSON.stringify(payload));
  filesList.forEach((i: any) => formData.append("images", i));
  return axios.put(baseUrl + "/marketplace", formData);
};
