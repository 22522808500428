import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export interface OrderState {
    receivedOrders: any[],
    processedOrders: any[],
    finishedOrders: any[],

    newOrderFailed: boolean,
    loadingNewOrder: boolean,

    ordersFailed: boolean,
    loadingOrders: boolean,

    orderReceived: boolean,
    orderPending: boolean,

    finishOrderFailed: boolean,
    loadingFinishOrder: boolean,

    processOrderFailed: boolean,
    processingOrder: boolean,

    prepareOrderFailed: boolean,
    preparingOrder: boolean,

    setSentToPOSFailed: boolean,
    loadingSentToPOS: boolean,

    articleAvailability: null,

    transactionsFailed: boolean,
    loadingTransactions: boolean,

    activateWhistle: boolean,

    saveOrderPOSErrorFailed: boolean,
    loadingSaveOrderPOSError: boolean,

    orderSocketConnected: boolean | null,
    orderSocketConnectionStart: boolean,

    thisOrder: any,

    newOrderNotification: boolean
}

const initialState: OrderState = {
    receivedOrders: [],
    processedOrders: [],
    finishedOrders: [],

    newOrderFailed: false,
    loadingNewOrder: false,

    ordersFailed: false,
    loadingOrders: false,

    orderReceived: true,
    orderPending: false,

    finishOrderFailed: false,
    loadingFinishOrder: false,

    processOrderFailed: false,
    processingOrder: false,

    prepareOrderFailed: false,
    preparingOrder: false,

    setSentToPOSFailed: false,
    loadingSentToPOS: false,

    articleAvailability: null,

    transactionsFailed: false,
    loadingTransactions: false,

    activateWhistle: false,

    saveOrderPOSErrorFailed: false,
    loadingSaveOrderPOSError: false,

    orderSocketConnected: null,
    orderSocketConnectionStart: false,

    thisOrder: null,

    newOrderNotification: false
}

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState: initialState,
    reducers: {
        orderReceived: (state, action) => {
            state.orderReceived = action.payload;
        },
        orderPending: (state, action) => {
            state.orderPending = action.payload;
            state.orderReceived = false;
        },
        fetchNewOrderStart: (state) => {
            state.loadingNewOrder = true;
            state.newOrderFailed = false
        },
        newOrderReceived: (state, action) => {
            state.receivedOrders = [...state.receivedOrders, action.payload];
            state.loadingNewOrder = false;
            state.newOrderFailed = false;
            state.activateWhistle = true;
            // state.playSound = true;
        },
        fetchNewOrderFailed: (state) => {
            state.loadingNewOrder = false;
            state.newOrderFailed = true;
        },
        fetchOrdersStart: (state) => {
            state.ordersFailed = false;
            state.loadingOrders = true;
        },
        fetchOrdersFailed: (state) => {
            state.ordersFailed = false;
            state.loadingOrders = true;
        },
        orders: (state, action) => {
            // order.status 'CREATED' 'PROCESSED'-u obradi 'PREPARED'-spakovano
            // TRANSAKCIJE 'CANCELED' 'REJECTED' 'TAKEN'
            state.receivedOrders = [];
            state.processedOrders = [];
            let sortedArray = [...action.payload]
            sortedArray.sort((a, b) => moment(a.created).isBefore(moment(b.created)) ? 1 : -1)
            sortedArray.forEach((order: any) => ((order.status === 'PROCESSED' || order.status === 'PREPARED') ? state.processedOrders : state.receivedOrders).push(order));
            state.ordersFailed = false;
            state.loadingOrders = false;
        },
        driverOrders: (state, action) => {

            const userId = localStorage.getItem("id")

            state.receivedOrders = [];
            state.processedOrders = [];
            state.finishedOrders = [];
            action.payload.forEach(
                (order: any) => 
                {
                    if(order.userDeliveryResponse === null) {
                        state.receivedOrders.push(order)
                    } else {
                        if((order.status === "IN_DELIVERY" || order.status === "PREPARED") && order.userDeliveryResponse.id == userId) {
                            state.processedOrders.push(order)
                        }

                        if (order.status === "TAKEN" && order.userDeliveryResponse.id == userId) {
                            state.finishedOrders.push(order)
                        }
                    }

            }
                );

            state.ordersFailed = false;
            state.loadingOrders = false;
        },
        setSentToPosFlagSuccess: (state, action) => {
            state.receivedOrders = state.receivedOrders.map((order) => {
                let match = 0;
                action.payload.forEach((orderId: any) => {
                    if (orderId.orderId === order.id) {
                        match = orderId.orderPOSId;
                    }
                })
                if (match !== 0) {
                    return {
                        ...order,
                        orderPOSId: match
                    }
                } else {
                    return {...order}
                }
            })
            state.setSentToPOSFailed = false;
            state.loadingSentToPOS = false;
        },
        setSentToPosFlagFailed: (state) => {
            state.setSentToPOSFailed = true;
            state.loadingSentToPOS = false;
        },
        setSentToPosFlagStart: (state) => {
            state.setSentToPOSFailed = false;
            state.loadingSentToPOS = true;
        },
        savedPOSError: (state) => {
            state.saveOrderPOSErrorFailed = false;
            state.loadingSaveOrderPOSError = false;
        },
        saveOrderPOSErrorFailed: (state) => {
            state.saveOrderPOSErrorFailed = true;
            state.loadingSaveOrderPOSError = false;
        },
        saveOrderPOSErrorStart: (state) => {
            state.saveOrderPOSErrorFailed = false;
            state.loadingSaveOrderPOSError = true;
        },
        toggleOrderModal: (state, action) => {
            state.thisOrder = action.payload
        },
        processOrder: (state, action) => {

            state.receivedOrders = state.receivedOrders.filter((order) => {
                if (order.id === action.payload.orderId) {
                    state.processedOrders.push(
                        {
                            ...order,
                            status: "PROCESSED",
                            willBeReadyIn: action.payload.responseData.willBeReadyIn,
                            willBeReadyInDate: action.payload.responseData.willBeReadyInDate,
                            modified: action.payload.responseData.modified,
                            noteForUser: action.payload.noteForUser
                        }
                    )
                } else {
                    return order;
                }
            })

            state.processOrderFailed = false;
            state.processingOrder = false;
        },
        processOrderFailed: (state) => {
            state.processOrderFailed = true;
            state.processingOrder = false;
        },
        processOrderStart: (state) => {
            state.processOrderFailed = false;
            state.processingOrder = true;
        },
        preparedOrder: (state, action) => {
            state.processedOrders = state.processedOrders.map((order) => {
                if (order.id === action.payload) {
                    return {
                        ...order,
                        status: "PREPARED"
                    }
                } else {
                    return order;
                }
            })
        },
        preparedOrderFailed: (state) => {
            state.prepareOrderFailed = true;
            state.preparingOrder = false;
        },
        preparedOrderStart: (state) => {
            state.prepareOrderFailed = false;
            state.preparingOrder = true;
        },
        finishOrderStart: (state) => {
            state.loadingFinishOrder = true;
            state.finishOrderFailed = false;
        },
        finishOrder:(state, action) => {
            state.processedOrders = state.processedOrders.filter((order) => {
                if (order.id === action.payload.cncOrderId) {
                    if (action.payload.status === "CANCELED") {
                        state.finishedOrders = [...state.finishedOrders, {...order, status: action.payload.status, noteForCanceledStatus: action.payload.note}]
                    } else {
                        state.finishedOrders = [...state.finishedOrders, {...order, status: action.payload.status}]
                    }
                }
                else {
                    return order;
                }
            })
            state.loadingFinishOrder = false;
            state.finishOrderFailed = false;
        },
        rejectOrder:(state, action) => {
            state.receivedOrders = state.receivedOrders.filter((order) => {
                if (order.id === action.payload.cncOrderId) {
                    state.finishedOrders = [...state.finishedOrders, {...order, status:"REJECTED", noteForUser: action.payload.note}]
                }
                else {
                    return order;
                }
            })
            state.loadingFinishOrder = false;
            state.finishOrderFailed = false;
        },
        finishOrderFailed:(state) => {
            state.loadingFinishOrder = false;
            state.finishOrderFailed = true;
        },
        orderSocketConnectionStart:(state) => {
            state.orderSocketConnected = null;
            state.orderSocketConnectionStart = true;
        },
        orderSocketConnectionChanged:(state, action) => {
            state.orderSocketConnected = action.payload;
            state.orderSocketConnectionStart = false;
        },
        fetchTransactionsStart:(state) => {
            state.transactionsFailed = false;
            state.loadingTransactions = true;
        },
        transactions:(state, action) => {
            let transactions = action.payload;
            const cutoff = moment().subtract(72, "hours");
            transactions = transactions.filter((transaction: any) => moment(transaction.created).isAfter(cutoff));
            transactions = transactions.sort((a:any, b:any) => moment(a.created).isBefore(moment(b.created)) ? 1 : -1).map((transaction : any) => {
                return {...transaction, id: transaction.orderNumber, transactionNumber: transaction.id}
            })
            state.finishedOrders = [...transactions];
            state.transactionsFailed = false;
            state.loadingTransactions = false;
        },
        fetchTransactionsFailed:(state) => {
            state.transactionsFailed = true;
            state.loadingTransactions = false;
        },
        clearOrdersAndTransactions:(state) => {
            state.receivedOrders = [];
            state.processedOrders = [];
            state.finishedOrders = [];
        },
        newOrderDesktopNotification: (state, action) => {
            state.newOrderNotification = action.payload
        }
    }
});

export const orderActions = orderSlice.actions

export default orderSlice