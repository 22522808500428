import React, { useEffect, useState } from "react";
import styles from "./PharmaciesImagesModal.module.scss";
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import Button from "../../UI/Buttons/Button/Button";
import editIcon from "../../../assets/icons/edit.svg";
import MediaUploadWrap from "../../MediaUploadWrap/MediaUploadWrap";

type Props = {
  currentImages: any[];
  submitImageChange: Function;
  show: boolean;
  setShow: Function;
};

function PharmaciesImagesModal({ currentImages, submitImageChange, show, setShow }: Props) {
  const [imgList, setImgList] = useState<any[]>([]);
  const [initImageList, setInitImageList] = useState(currentImages);

  useEffect(() => {
    const remappedInitialImages = currentImages?.map((image) => ({
      ...image,
      imageLink: process.env.REACT_APP_CDN_PATH + "marketplace_images/" + image.location,
    }));
    setInitImageList(remappedInitialImages);
    setImgList(remappedInitialImages || []);
  }, [currentImages]);

  return (
    <>
      <Button className={styles.editButton} onClick={() => setShow(true)} buttonType="white">
        <img className={styles.Icons} src={editIcon} />
      </Button>
      {show && (
        <SimpleModal backdropClickHandler={() => setShow(false)} show={show} className={styles.Modal}>
          <MediaUploadWrap imageList={imgList} setImageList={setImgList} />
          <Button
            onClick={() => {
              submitImageChange(imgList, initImageList);
            }}
            buttonType="blue">
            Sacuvaj
          </Button>
          <Button onClick={() => setShow(false)} buttonType="white">
            Odustani
          </Button>
        </SimpleModal>
      )}
    </>
  );
}

export default PharmaciesImagesModal;
