import React, { useEffect, useState } from "react";
import styles from "./OrderProcessingPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ReceivedColumn from "../../components/OrderProcessingComponents/Columns/ReceivedColumn/ReceivedColumn";
import ProcessedColumn from "../../components/OrderProcessingComponents/Columns/ProcessedColumn/ProcessedColumn";
import ArchivedColumn from "../../components/OrderProcessingComponents/Columns/ArchiveColumn/ArchivedColumn";
import {
  fetchAllOrdersForAdminUser,
  fetchAllOrdersForDriverUser,
  fetchAllTransactionsForAdminUser,
  fetchOrders,
  fetchTransactions,
} from "../../store/order-actions";
import { fetchAllRFZOTherapyRequests, fetchRFZOTherapyRequests } from "../../store/rfzoTherapy-actions";
import { orderActions } from "../../store/order-slice";
import { pingActiveStatus } from "../../store/auth-actions";
import PendingColumnDriver from "../../components/OrderProcessingComponents/Columns/PendingColumnDriver/PendingColumnDriver";
import Tabs from "../../components/UI/Tabs/Tabs";
import DriverArchivedColumn from "../../components/OrderProcessingComponents/Columns/DriverArchievedColumn/DriverArchievedColumn";

const OrderProcessingPage = () => {
  const dispatch = useDispatch();
  const thisOrder = useSelector((state: RootState) => state.order.thisOrder);
  const rfzoRequest = useSelector((state: RootState) => state.rfzoTherapy.rfzoRequest);
  const marketplaceInfo = useSelector((state: RootState) => state.auth.marketplaceInfo);

  const [isDriverRole, setIsDriverRole] = useState(false);
  const [isAdminState, setIsAdminState] = useState(false);
  const [marketPlaceInfoFetched, setMarketPlaceInfoFetched] = useState(false);

  useEffect(() => {
    if (marketplaceInfo && marketplaceInfo.takesOrders === 1) {
      const interval = setInterval(() => {
        dispatch<any>(pingActiveStatus(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
      }, 120000);
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [marketplaceInfo]);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      let isAdmin = false;
      let isPharmacist = false;
      let isDriver = false;
      localStorage
        .getItem("roles")
        ?.split(",")
        .map((role) => {
          if (role === "ADMIN") {
            isAdmin = true;
            setIsAdminState(true);
          }
          if (role === "PHARMACIST_SUPPORT_USER") {
            isPharmacist = true;
          }
          if (role === "DELIVERY_USER") {
            setIsDriverRole(true);
            isDriver = true;
          }
        });
      if (!isPharmacist) {
        if (isAdmin) {
          dispatch<any>(fetchAllOrdersForAdminUser());
          dispatch<any>(fetchAllTransactionsForAdminUser());
          dispatch<any>(fetchAllRFZOTherapyRequests());
        } else if (isDriver) {
          console.log('isDriver')
          dispatch<any>(fetchAllOrdersForDriverUser());
        } else {
          if (marketPlaceInfoFetched && marketplaceInfo !== null) {
            dispatch<any>(fetchOrders(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
            dispatch<any>(fetchTransactions(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
            dispatch<any>(fetchRFZOTherapyRequests(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
          }
        }
      }
    }
    return () => {
      dispatch(orderActions.clearOrdersAndTransactions());
    };
  }, [marketPlaceInfoFetched]);

  useEffect(() => {
    let isDriver = false;
    let isMarketPlace = false;
    localStorage
      .getItem("roles")
      ?.split(",")
      .map((role) => {
        if (role === "DELIVERY_USER") {
          isDriver = true;
        }
        if (role === "PRODAJNO_MESTO_USER") {
          isMarketPlace = true;
        }
      });

    if (isDriver) {
      const interval = setInterval(() => {
        console.log('isDriver')
        dispatch<any>(fetchAllOrdersForDriverUser());
      }, 10000);
      return () => clearInterval(interval);
    }
    if (isMarketPlace && marketplaceInfo) {
      const interval = setInterval(() => {
        dispatch<any>(fetchOrders(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId, false));
        dispatch<any>(fetchTransactions(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
        dispatch<any>(fetchRFZOTherapyRequests(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [marketplaceInfo]);

  useEffect(() => {
    if (marketplaceInfo) {
      setMarketPlaceInfoFetched(true);
    } else {
      setMarketPlaceInfoFetched(false);
    }
  }, [marketplaceInfo]);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992)


  useEffect(() => {

    const handleResize = () => {
        console.log(window.innerWidth);
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <>
          {isDriverRole ? (
            <DriverTabs onChange={(tab) => setSelectedTab(tab)} selected={selectedTab} />
          ) : (
            <MerchantTabs onChange={(tab) => setSelectedTab(tab)} selected={selectedTab} />
          )}
        </>
      )}
      <div className={styles.OrderProcessingPage}>
        {!isDriverRole ? (
          <>
            {((isMobile && selectedTab == 0) || !isMobile) && <ReceivedColumn isAdmin={isAdminState} />}
            {((isMobile && selectedTab == 1) || !isMobile) && (
              <ProcessedColumn title="Spakovane" isAdmin={isAdminState} />
            )}
            {((isMobile && selectedTab == 2) || !isMobile) && <ArchivedColumn isAdmin={isAdminState} />}
          </>
        ) : (
          <>
            {((isMobile && selectedTab == 0) || !isMobile) && <PendingColumnDriver isDriver={true} isAdmin={isAdminState} />}
            {((isMobile && selectedTab == 1) || !isMobile) && <ProcessedColumn title="Preuzete" isDriver={isDriverRole} isAdmin={isAdminState} />}
            {((isMobile && selectedTab == 2) || !isMobile) && <DriverArchivedColumn isAdmin={isAdminState} />}
          </>
        )}
        {/* {thisOrder && <OrderModal show={thisOrder} order={thisOrder} isDriver={isDriverRole} isAdmin={isAdminState} />} */}
      </div>
    </>
  );
};

export default OrderProcessingPage;



type Props = {
  selected: number;
  onChange: (tab: any) => any;
};

const MerchantTabs = ({ onChange, selected }: Props) => {
  const orderState = useSelector((state: RootState) => state.order);
  const rfzoTherapy = useSelector((state: RootState) => state.rfzoTherapy);

  const rfzoRequestRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
    return rfzoRequest.status === "REQUESTED";
  });

  const rfzoRequestNotRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
    return rfzoRequest.status !== "REQUESTED"
})

  const pedningCount = orderState.receivedOrders.length + rfzoRequestRequested.length;
  const processedCount = orderState.processedOrders.length.toString();
  const archievedCount = orderState.finishedOrders.length + rfzoRequestNotRequested.length

  console.log(orderState.finishedOrders.length)

  const tabsList = [
    {
      label: "Pristiglih",
      count: pedningCount,
    },
    {
      label: "Spakovane",
      count: processedCount,
    },
    {
      label: "Obradjenih",
      count: archievedCount,
    },
  ];

  return <Tabs onChange={onChange} selected={selected} tabsList={tabsList} />;
};
const DriverTabs = ({ onChange, selected }: Props) => {
  const orderState = useSelector((state: RootState) => state.order);
  const rfzoTherapy = useSelector((state: RootState) => state.rfzoTherapy);

  const rfzoRequestRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
    return rfzoRequest.status === "REQUESTED";
  });

  const pedningCount = orderState.receivedOrders.length + rfzoRequestRequested.length;
  const processedCount = orderState.processedOrders.length.toString();
  const archievedCount = orderState.finishedOrders.length + rfzoRequestRequested.length;

  const tabsList = [
    {
      label: "Pristiglih",
      count: pedningCount,
    },
    {
      label: "Preuzetih",
      count: processedCount,
    },
    {
      label: "Dostavljenih",
      count: archievedCount,
    },
  ];

  return <Tabs onChange={onChange} selected={selected} tabsList={tabsList} />;
};