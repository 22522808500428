import React, {useState} from 'react';
import styles from "./ConfirmationModal.module.scss";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Buttons/Button/Button";
import {ReactComponent as XIcon} from "../../../../assets/icons/x-icon.svg";
import {finishOrderAction} from "../../../../store/order-actions";
import {useDispatch} from "react-redux";
import {orderActions} from "../../../../store/order-slice";
import {rejectRFZORequest} from "../../../../store/rfzoTherapy-actions";

type Props = {
    show: boolean;
    noBackdrop?: boolean;
    backdropClickHandler: React.MouseEventHandler;
    order: any,
    noteForUser?: string
}
const ConfirmationModal = ({show, noBackdrop, backdropClickHandler, order, noteForUser}: Props) => {

    const dispatch = useDispatch();

    const [noteForCancelStatus, setNoteForCancelStatus] = useState<string>("");
    const [rejectValidation, setRejectValidation] = useState(false);

    const rejectOrderHandler = () => {
        if (order.status === 'CREATED') {
            dispatch<any>(finishOrderAction(order.id, "REJECTED", noteForCancelStatus, order.orderPOSId));
        } else {
            dispatch<any>(finishOrderAction(order.id, "CANCELED", noteForCancelStatus, order.orderPOSId));
        }
        dispatch(orderActions.toggleOrderModal(null));  // closeModal();
    }

    const rejectRFZORequestHandler = () => {
        if(order.status === "REQUESTED") {
            dispatch<any>(rejectRFZORequest(order.id));
        }
    }

    const noteForCancelStatusAction = (e: any) => {
        setNoteForCancelStatus(e.target.value);
    }

    const checkForNoteForCancel = () => {
        if (["PREPARED", "CREATED"].includes(order.status) && noteForCancelStatus.length < 3) {
            setRejectValidation(true)
        } else {
            rejectOrderHandler();
        }
        //rejecting rfzoRequest
        if(order.userLBO) {
            rejectRFZORequestHandler();
        }
    }

    return (
        <>
            <div className={styles.ConfirmationModal}>
                <div className={styles.ConfirmationModalHeader}>
                    <div style={{textAlign: "center", flex: 2}}>Otkaži porudžbinu</div>
                    <XIcon className={styles.XIcon} onClick={backdropClickHandler}/>
                </div>
                <div className={styles.ConfirmationModalContent}>

                            <div id="NoteForUser" style={{height:"100%"}}
                                 onFocus={() => setRejectValidation(false)}>
                                <textarea className={styles.TextArea} placeholder="Poruka za korisnika"
                                          onChange={noteForCancelStatusAction}/>
                                <label htmlFor="NoteFromUser" className={styles.Label}>
                                    {rejectValidation &&
                                        <span style={{color: "Red"}}>(obavezno kod oktazivanja porudzbine)</span>}
                                </label>
                            </div>

                    <Button buttonType="red" onClick={checkForNoteForCancel}>Otkaži</Button>
                </div>

            </div>
            {!noBackdrop &&
                <Backdrop show={show}
                          backdropClickHandler={backdropClickHandler}
                          zIndex={40}
                          backgroundColor="rgba(0, 0, 0, .2)"/>
            }
        </>
    );
};

export default ConfirmationModal;