import React, { useCallback } from "react";
import styles from "./MediaUploadWrap.module.scss";
import { useDropzone } from "react-dropzone";
import uploaderImage from "../../assets/icons/uploader.svg";
import TrashIcon from "@rsuite/icons/Trash";

function MediaUploadWrap({ imageList, setImageList, maxNumber = 10 }: any) {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setImageList([...imageList, ...acceptedFiles]);
    },
    [imageList]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: maxNumber });

  const onRemove = (index: number) => {
    const list = [...imageList];
    list.splice(index, 1);
    setImageList(list);
  };

  return (
    <div style={{width: "100%"}}>
      <div className={styles.inputWrap} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.inputHandler}>
          <img src={uploaderImage} />
          {isDragActive ? <p>Prevuci ovde ...</p> : <p>Dodajte fajl ili ga prevucite u polje. (Max 10MB)</p>}
        </div>
      </div>
      <div className={styles.fileList}>
        {imageList?.map((item: any, index: any) => {
          return <MediaItem item={item} index={index} onRemove={onRemove} />;
        })}
      </div>
    </div>
  );
}

export default MediaUploadWrap;

const MediaItem = ({ item, index, onRemove }: any) => {
  return (
    <div className={styles.mediaContainer}>
      {item?.type?.toLowerCase()?.includes("image") && (
        <img alt="img" className={styles.image} src={item?.imageLink || URL.createObjectURL(item)} />
      )}
      {item?.type?.toLowerCase()?.includes("video") && (
        <video autoPlay className={styles.video} src={item?.imageLink || URL.createObjectURL(item)} />
      )}
      <button className={styles.trashIcon} onClick={() => onRemove(index)}>
        <TrashIcon />
      </button>
    </div>
  );
};
