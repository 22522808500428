import React, { useState } from "react";
import styles from "./OrderBoxV2.module.scss";
import { ReactComponent as RFZOIcon } from "../../../assets/icons/rfzo-icon.svg";
import { useDispatch } from "react-redux";
import { orderActions } from "../../../store/order-slice";
import { rfzoTherapyActions } from "../../../store/rfzoTherapy-slice";
import moment from "moment";
import Button from "../../UI/Buttons/Button/Button";
import ProgressBar from "../../ProgressBar/ProgressBar";
import OrderDetails from "../OrderDetails/OrderDetails";
import RFZOOrderDetails from "../../RFZOOrderDetails/RFZOOrderDetails";
import classNames from "classnames";
import ArrowUpIcon from '@rsuite/icons/ArrowUp';


type Props = {
  order?: any;
  rfzoRequest?: any;
  isAdmin?: boolean;
  isDriver?: boolean;
  archived?: boolean;
  processed?: boolean;
};

const SUCCESS_STATUSES = ["TAKEN", "IN_DELIVERY"];

const OrderBox = ({ order, rfzoRequest, isAdmin, processed, isDriver, archived }: Props) => {
  let orderBoxData: any;



  if (rfzoRequest) {
    console.log(rfzoRequest)
    orderBoxData = {
      ...rfzoRequest,
      id: rfzoRequest.id,
      date: new Date("" + rfzoRequest.created),
      firstName: rfzoRequest.firstName,
      secondName: rfzoRequest.secondName,
      phoneNumber: rfzoRequest.userPhone,
      status: rfzoRequest.status,
      userLBO: rfzoRequest.userLBO,
      deliveryPrice: order?.deliveryPrice,
      totalPriceWithDelivery: order?.totalPriceWithDelivery,
      isRFZO: true,
    };
  } else {
    orderBoxData = {
      ...order,
      id: order.id,
      date: new Date("" + order.created),
      firstName: order.firstName,
      secondName: order.secondName,
      phoneNumber: order.userPhoneNumberForOrder || order?.userPhoneNumber,
      items: order.items,
      noteForUser: order.noteForUser,
      rfzoTherapyNumber: order.rfzoTherapyNumber,
      status: order.status,
      totalPrice: order.totalPrice,
      deliveryPrice: order.deliveryPrice,
      totalPriceWithDelivery: order.totalPriceWithDelivery,
    };
  }

  const dispatch = useDispatch();

  const showModal = () => {
    if (rfzoRequest) {
      setRfzoExpanded(true);
      dispatch(rfzoTherapyActions.toggleRFZORequestModal(orderBoxData));
    } else {
      dispatch(orderActions.toggleOrderModal(order));
      setExpanded(true);
    }
  };

  const [expanded, setExpanded] = useState(false);
  const [rfzoExpanded, setRfzoExpanded] = useState(false);

  const isSuccessful = SUCCESS_STATUSES.includes(orderBoxData.status);

  return (
    <div
      className={classNames(styles.orderBox, {
        [styles.success]: archived && isSuccessful,
        [styles.failed]: archived && !isSuccessful,
      })}>
      <div className={styles.cardHeader}>
        <div className={styles.dataAndId}>
          <span className={styles.orderDate}>{moment(orderBoxData.date).format("DD.MM.YYYY")}</span>
          <br />
          <span className={styles.orderId}>#{orderBoxData.id}</span>
        </div>
        <div className={styles.timerAndStatus}>
          {archived ? (
            <div
              className={classNames(styles.orderStatus, {
                [styles.success]: true,
                [styles.failed]: true,
              })}>
              {statusMapping[orderBoxData?.status]}
            </div>
          ) : (
            <ProgressBar intervalLength={10} intervalType={processed ? "h" : "m"} startPoint={orderBoxData.date} />
          )}
        </div>
      </div>
      {expanded && isAdmin && (
        <div>
          Apoteka: {orderBoxData?.marketplaceResponse?.name}, {orderBoxData?.marketplaceResponse?.location}
        </div>
      )}
      <div className={styles.cardBody}>
        <div className={styles.userName}>{orderBoxData?.firstName + " " + orderBoxData?.secondName}
        <br/>
        {(orderBoxData.isRFZO || orderBoxData.rfzoTherapyNumber) && isAdmin && <div style={{fontWeight: "400", fontSize: "14px"}}>{orderBoxData?.marketplaceResponse?.name}</div>}

        </div>
        <div className={styles.rfzoAndPrice}>
          {(orderBoxData.isRFZO || orderBoxData.rfzoTherapyNumber) && (
            <div>
              RFZO <RFZOIcon />
              <br />
              <div className={styles.porudzbinaLabel}>{!orderBoxData?.rfzoTherapyNumber ? "ZAHTEV" : "PORUDŽBINA"}</div>
            </div>
          )}
          {archived && orderBoxData?.totalPrice + " RSD"}
        </div>
      </div>
      {!expanded && !rfzoExpanded && (
        <div className={styles.cardFooter}>
          <Button
            style={{ fontSize: "14px" }}
            onClick={() => {
              showModal();
            }}
            buttonType={(archived && isSuccessful) || !archived ? "blue" : "gray"}>
            {!processed && !archived && "Obradi"}
            {processed && "Evidentiraj"}
            {archived && "Otvori"}
          </Button>
        </div>
      )}
      {expanded && (
        <OrderDetails
          archived={archived}
          collapseOrder={() => setExpanded(false)}
          order={order}
          isDriver={false}
          isAdmin={!!isAdmin}
        />
      )}
      {rfzoExpanded && <RFZOOrderDetails archived={archived} rfzoRequest={orderBoxData} show={rfzoExpanded} />}

      {(expanded || rfzoExpanded) && (
        <div
          style={{ textAlign: "center", color: "#a9a9a9", fontSize: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (expanded || rfzoExpanded) {
              setExpanded(false);
              setRfzoExpanded(false);
              dispatch(orderActions.toggleOrderModal(null));
            }
          }}>
          <ArrowUpIcon height={32} width={32} />
        </div>
      )}
    </div>
  );
};

export default OrderBox;

interface Statuses {
  [key: string]: string;
}

const statusMapping: Statuses = {
  CREATED: "Kreirano",
  REQUESTED: "Zatrazeno",
  PREPARED: "Pripremljeno",
  PROCESSED: "Obradjeno",
  TAKEN: "Preuzeto",
  IN_DELIVERY: "Preuzeto",
  REJECTED: "Odbijeno",
  CANCELED: "Otkazano",
  REQUEST_EXPIRED: "Propušteno",
  PROCESSED_WITHOUT_CONTENT: "Odbijeno",
  REJECT_REQUESTED: "Odbijeno",
};
