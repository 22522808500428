import React, {useEffect, useState} from 'react';
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import ImageUploader from "../../UI/ImageUploader/ImageUploader";
import styles from './NewCommercial.module.scss';
import Button from "../../UI/Buttons/Button/Button";
import {set, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {createNewAdvertisement} from "../../../store/admin-cms-actions";
import {adminCMSActions} from "../../../store/admin-cms-slice";
import MediaUploadWrap from '../../MediaUploadWrap/MediaUploadWrap';


type Props = {
    show: boolean,
    closeNewCommercialModal: () => void,
    mobile?: boolean,
    web?: boolean
}

type Commercial = {
    title?: string,
    text?: string,
    actionType: string,
    actionLink: string,
    backgroundColor: string,
    type: string,
    idRelatedGroup?: number
    images: any[]
}

const NewCommercial = ({show, closeNewCommercialModal, web, mobile} :Props) => {

    const dispatch = useDispatch();
    const uploadedImageData = useSelector((state:RootState) => state.adminCMS.uploadedImageData);
    const createNewAdvertisementSuccess = useSelector((state:RootState) => state.adminCMS.createNewAdvertisementSuccess);

    const [newCommercialObject, setNewCommercialObject] = useState({
            title: '',
            text: '',
            actionType: '',
            actionLink: '',
            backgroundColor: '',
            type: '',
            idRelatedGroup: null,
            images: [],
            priority: 1
    })

    const [isObjectReady, setIsObjectReady] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

    const {register, handleSubmit, formState: {errors, isDirty, isValid}, getValues} = useForm({
        mode: "onSubmit",
    });

    useEffect(()=>{
        return() => {
            dispatch(adminCMSActions.resetUploadedImageData());
        }
    }, [])

    const onSubmit = (data: any) => {
        if ((uploadedImageData.length > 0 || filesList.length > 0) && isDirty && isValid) {

            let images
            let text

            // dodata podrska za upload videa za mobile reklame, ostala logika za web reklame
            if (mobile) {
              text = filesList[0].name;
              images = filesList[0]
            } else {
              text = uploadedImageData.map((image: any, index) => (index === 0 ? image.file.name : "")).toString();
              images = uploadedImageData.map((image:any, index: number) => index === 0 && image.file)
            }
            const values = getValues();
            // @ts-ignore
            setNewCommercialObject({
                ...newCommercialObject,
                title: values.title,
                text: text,
                priority: values.priority,
                actionType: values.actionType,
                actionLink: values.actionLink,
                backgroundColor: values.color,
                type: values.advertisementType,
                idRelatedGroup: null,
                //@ts-ignore
                images: images
            })

            setIsObjectReady(true)
        }
    }
    const [filesList, setFilesList] = useState<any[]>([]);

    useEffect(()=>{
        if (isObjectReady) {
            const fileObj = new FormData();
            if(mobile) {
                fileObj.append('images', filesList[0]);
            } else {
                fileObj.append('images', uploadedImageData[0].file);
            }

            const uploadedData = {
                title: newCommercialObject.title,
                text: newCommercialObject.text,
                priority: newCommercialObject?.priority,
                actionType: newCommercialObject.actionType,
                actionLink: newCommercialObject.actionLink,
                backgroundColor: newCommercialObject.backgroundColor,
                type: newCommercialObject.type,
                fileType: filesList[0]?.type?.toLowerCase()?.includes("video") ? "VIDEO" : "IMAGE",
                idRelatedGroup: null,
            }

            fileObj.append('advertisement', JSON.stringify(uploadedData));
            dispatch<any>(createNewAdvertisement(fileObj))
        }
    }, [isObjectReady, mobile, web, filesList]);

    useEffect(()=> {
        if(createNewAdvertisementSuccess) {
            closeNewCommercialModal();
            dispatch(adminCMSActions.resetCreateNewAdvertisement());
        }
    },  [createNewAdvertisementSuccess])

    const setCancelModalVisibility = () => {
        setIsCancelModalVisible(prev => !prev)
    }

    const confirmCancellation = () => {
        setIsCancelModalVisible(false);
        closeNewCommercialModal();
    }



    const setFileUpload = (files: any) => {
        console.log(files)
        const fileLength = files.length
        if (fileLength === 0) {
            setFilesList([]);
        } else
        setFilesList([files[fileLength - 1]]);
    }

    return (
        <SimpleModal show={show} className={styles.Modal} >
            <div className={styles.ModalTitle}>Kreiranje nove reklame</div>
            <div className={styles.UploadFormWrapper}>
                {web &&<ImageUploader/>}
                {mobile &&<MediaUploadWrap setImageList={setFileUpload} imageList={filesList} maxNumber={1} />}
                <div className={styles.Details}>
                    <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.OptionsWrapper}>
                            <label htmlFor='image-name'>Naziv slike</label>
                            <input id="image-name"
                                   type="text"
                                   className={styles.Option}
                                   placeholder="Naziv"
                                   disabled={true}
                                   value={uploadedImageData.map((image:any, index:number) =>{
                                       if(index === 0){
                                           return (image.file.name)
                                       }
                                   })}
                                   {...register("text", {
                                required: false,
                                maxLength: 80
                            })} />
                            <br/>
                            {web &&
                                <>
                                    <label htmlFor='background-color'>Boja pozadine (obavezno polje)</label>
                                    <input id="background-color" className={styles.Option} type="text"
                                           placeholder="Boja pozadine"
                                           {...register("color", {
                                        required: true,
                                        maxLength: 100
                                    })} />
                                    <br/>
                                </>
                            }
                            {mobile &&
                                <>
                                    <label htmlFor='title'>Naslov reklame</label>
                                    <input id="title"
                                           type="text"
                                           className={styles.Option}
                                           placeholder="Naslov reklame"
                                           {...register("title", {required: true})} />
                                    <br/>
                                </>
                            }
                            <label htmlFor="advertisement-type">Tip reklame (obavezno polje)</label>
                            {web &&
                            <select id="advertisement-type"
                                    className={styles.Option} {...register("advertisementType", {
                                        required: true
                                    })}>
                                <option disabled selected value=''>Izaberite tip</option>
                                <option value="web">Web</option>
                                <option value="web_group">WebGroup</option>
                            </select> }
                            {mobile &&
                                <input id="advertisement-type"
                                       type="text"
                                       className={styles.Option}
                                       value="mobile"
                                       readOnly
                                       {...register("advertisementType", {
                                           required: false
                                       })}>
                                </input>}
                            {mobile &&
                            <>
                            <br />
                            <label htmlFor="priority">Prioritet</label>
                            
                                <input id="priority"
                                       type="number"
                                       step={1}
                                       className={styles.Option}
                                        min={1}
                                       {...register("priority", {
                                           required: true
                                       })}>
                                </input>
                            </>
                                }

                            <br/>
                            <label htmlFor="advertisement-action-type">Action Type</label>
                            {mobile &&
                                <select id="advertisement-action-type"
                                        className={styles.Option} {...register("actionType", {
                                    required: true
                                })}>
                                    <option disabled selected value=''>Izaberite tip</option>
                                    <option value="ExtURL">ExtURL</option>
                                    <option value="Dostupnost">Dostupnost</option>
                                    <option value="Search">Search</option>
                                    <option value="RFZO">RFZO</option>
                                    <option value="Loyalty">Loyalty</option>
                                    <option value="InfoLek">InfoLek</option>
                                    <option value="Kategorija">Kategorija</option>
                                    <option value="FilterProizvod">FilterProizvod</option>
                                    <option value="FilterApoteka">FilterApoteka</option>
                                    <option value="ShopApoteka">ShopApoteka</option>
                                </select>
                            }
                            {web &&
                            <input className={styles.Option}
                                   id='advertisement-action-type'
                                   type="text"
                                   placeholder="Action Type"
                                   {...register("actionType", {required: false})} />
                            }
                            <br/>
                            <label htmlFor="action-link">Action Link</label>
                            <input className={styles.Option}
                                   id='action-link'
                                   type="text"
                                   placeholder="Action Link"
                                   {...register("actionLink", {required: false})} />
                        </div>
                        <div className={styles.ActionsWrapper}>
                            <input
                                className={(!isDirty || !isValid) ? `${styles.Submit} ${styles.SubmitDisabled}` : styles.Submit}
                                type="submit" value='Sačuvaj' disabled={!isDirty || !isValid}/>
                            <Button type="button" buttonType='red' onClick={setCancelModalVisibility}>Odustani</Button>
                        </div>
                    </form>
                </div>
                {isCancelModalVisible &&
                    <SimpleModal show={isCancelModalVisible}
                                 backdropClickHandler={setCancelModalVisibility}>
                        <div>Da li ste sigurni da želite da odustanete od kreiranja nove reklame?</div>
                        <div className={styles.ActionsWrapper}>
                            <Button buttonType="blue" onClick={confirmCancellation}>Da</Button>
                            <Button buttonType="red" onClick={setCancelModalVisibility}>Ne</Button>
                        </div>
                    </SimpleModal>
                }

            </div>
        </SimpleModal>
    );
};

export default NewCommercial;