import React, {useEffect, useState} from 'react';
import styles from './SingleCommercial.module.scss';
import config from '../../../config.json';
import {useForm} from "react-hook-form";
import Button from "../../UI/Buttons/Button/Button";
import {ReactComponent as TrashIcon} from "../../../assets/icons/trashIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {deleteAdvertisement, updateAdvertisement} from "../../../store/admin-cms-actions";
import SimpleModal from "../../UI/Modals/SimpleModal/SimpleModal";
import {adminCMSActions} from "../../../store/admin-cms-slice";
import {RootState} from "../../../store";

type Props = {
    commercial: any,
    mobile?: boolean,
    web?: boolean,
    className?: string
}

const SingleCommercial = ({commercial, mobile, web, className}: Props) => {

    const dispatch = useDispatch();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const updateAdvertisementSuccess = useSelector((state:RootState) => state.adminCMS.updateAdvertisementSuccess)

    useEffect(()=>{
        if(updateAdvertisementSuccess) {
            dispatch(adminCMSActions.resetUpdateAdvertisement());
        }
    }, [updateAdvertisementSuccess])

    let commercialProps = {
        actionLink: commercial.actionLink,
        actionType: commercial.actionType,
        fileType: commercial.fileType,
        backgroundColor: commercial.backgroundColor,
        id: commercial.id,
        imageLink: commercial.imageLink,
        text: commercial.text,
        title: commercial.title,
        priority: commercial.priority,
        type: commercial.type
    }

    const {register, handleSubmit, formState: {errors, isDirty, isValid}, getValues} = useForm({
        mode: "onSubmit",

    });
    const onSubmit = (data: any) => {
        if (isDirty && isValid) {
            const values = getValues();

            commercialProps = {...commercialProps,
                actionLink: values.actionLink,
                actionType: values.actionType,
                backgroundColor: values.backgroundColor,
            }

            dispatch<any>(updateAdvertisement(commercialProps))
        }
    }



    // useEffect(()=> {
    //     if(createNewAdvertisementSuccess) {
    //         closeNewCommercialModal();
    //         dispatch(adminCMSActions.resetCreateNewAdvertisement());
    //     }
    // },  [createNewAdvertisementSuccess]);

    const test = (e: any) => {
        console.log(e.target.value)
    }

    const deleteAdv = () => {
        dispatch<any>(deleteAdvertisement(commercialProps.id));
        setModalVisibility();
    }

    const setModalVisibility = () => {
        setIsDeleteModalVisible(prev => !prev)
    }

    return (
      <div className={`${styles.SingleCommercial} ${className}`}>
        {web && (
          <img
            alt="img"
            className={styles.Image}
            src={process.env.REACT_APP_CDN_PATH + "commecial_images/" + commercialProps.imageLink}
          />
        )}
        {mobile && (
          <>
            {commercialProps.fileType === "IMAGE" ? (
              <img
                alt="img"
                className={styles.Image}
                src={process.env.REACT_APP_CDN_PATH + "commercial_images_mobile/" + commercialProps.imageLink}
              />
            ) : (
              <video
                autoPlay
                className={styles.Image}
                src={process.env.REACT_APP_CDN_PATH + "commercial_images_mobile/" + commercialProps.imageLink}
              />
            )}{" "}
          </>
        )}

        <form className={styles.Details} onSubmit={handleSubmit(onSubmit)} onChange={(e) => test(e)}>
          <div className={styles.OptionsWrapper}>
            <label htmlFor="imageName">Naziv slike</label>
            <input
              id="imageName"
              type="text"
              className={styles.Option}
              disabled={true}
              defaultValue={commercialProps.imageLink}
              {...register("title", { required: false })}
            />
            <br />
            {mobile && (
              <>
                <label htmlFor="title">Naslov reklame</label>
                <input
                  id="title"
                  type="text"
                  className={styles.Option}
                  disabled={true}
                  defaultValue={commercialProps.title}
                  {...register("title", { required: false })}
                />
                <br />
              </>
            )}
            {web && (
              <>
                <label htmlFor="backgroundColor">Boja pozadine</label>
                <input
                  id="backgroundColor"
                  className={styles.Option}
                  type="text"
                  placeholder="Boja pozadine"
                  defaultValue={commercialProps.backgroundColor}
                  {...register("backgroundColor", {
                    required: true,
                    maxLength: 100,
                    pattern: {
                      value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
                      message: "Uneseni kod nije validnog formata",
                    },
                  })}
                />

                {
                  //@ts-ignore
                  errors?.Boja && <span className={styles.ValidationErrorMessage}>{errors.Boja.message}</span>
                }
                <br />
              </>
            )}
            <label htmlFor="advertisementType">Tip reklame</label>
            <input
              id="advertisementType"
              type="text"
              className={styles.Option}
              disabled={true}
              value={commercialProps.type}
              {...register("advertisementType", { required: false })}
            />

            {/*<select id="advertisement-type"*/}
            {/*        className={styles.Option} {...register("Tip reklame", {required: true})}>*/}
            {/*    <option value="Mobile" selected={commercialProps.type === 'mobile'}>Mobile</option>*/}
            {/*    <option value="Web" selected={commercialProps.type === 'web'}>Web</option>*/}
            {/*</select>*/}
            <br />
            <label htmlFor="advertisementActionType">Action Type</label>
            {mobile && (
              <select
                id="advertisement-action-type"
                className={styles.Option}
                {...register("actionType", {
                  required: true,
                })}>
                <option disabled selected value={commercialProps.actionType}>
                  {commercialProps.actionType}
                </option>
                <option value="ExtURL">ExtURL</option>
                <option value="Dostupnost">Dostupnost</option>
                <option value="Search">Search</option>
                <option value="RFZO">RFZO</option>
                <option value="Loyalty">Loyalty</option>
                <option value="InfoLek">InfoLek</option>
                <option value="Kategorija">Kategorija</option>
                <option value="FilterProizvod">FilterProizvod</option>
                <option value="FilterApoteka">FilterApoteka</option>
                <option value="ShopApoteka">ShopApoteka</option>
              </select>
            )}
            {web && (
              <input
                className={styles.Option}
                id="advertisementActionType"
                type="text"
                placeholder="Type"
                defaultValue={commercialProps.actionType}
                {...register("actionType", { required: false })}
              />
            )}
            {mobile && (
                <>
                <br/>
                <label>Prioritet</label>
              <input
                className={styles.Option}
                id="priority"
                type="number"
                placeholder="Prioritet"
                defaultValue={commercialProps.priority}
                {...register("priority", { required: true })}
              />
              </>
            )}
            <br />
            <label htmlFor="advertisementLink">Action Link</label>
            <input
              className={styles.Option}
              id="advertisementLink"
              type="text"
              placeholder="ActionLink"
              defaultValue={commercialProps.actionLink}
              {...register("actionLink", { required: false })}
            />
          </div>
          <div className={styles.ActionsWrapper}>
            <input
              className={!isDirty || !isValid ? `${styles.Submit} ${styles.SubmitDisabled}` : styles.Submit}
              type="submit"
              value="Sačuvaj izmene"
              disabled={!isDirty || !isValid}
            />
            <Button buttonType="red" onClick={setModalVisibility}>
              <TrashIcon className={styles.IconStyle} />
            </Button>
          </div>
        </form>
        {isDeleteModalVisible && (
          <SimpleModal show={isDeleteModalVisible} backdropClickHandler={setModalVisibility}>
            <div>Da li ste sigurni da želite da obrišete reklamu?</div>
            <div className={styles.ActionsWrapper}>
              <Button buttonType="blue" onClick={deleteAdv}>
                Da
              </Button>
              <Button buttonType="red" onClick={setModalVisibility}>
                Ne
              </Button>
            </div>
          </SimpleModal>
        )}
      </div>
    );
};

export default SingleCommercial;