import axios, {baseUrl} from "./../axios/axios";
import axios1 from "axios"
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {orderActions} from "./order-slice";
import axios_neutral from 'axios';
import {rfzoTherapyActions} from "./rfzoTherapy-slice";
import moment from "moment";

export const fetchNewOrder = (orderId: number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(orderActions.fetchNewOrderStart());
        const URL = '/click_and_collect_order/' + orderId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.newOrderReceived(response.data))
                if(response.data.rfzoTherapyNumber !== null)
                dispatch(rfzoTherapyActions.rfzoTherapyOrderReceived(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchNewOrderFailed())
            })
    };
}

export const uploadDeliveryConsent = (formData: any, userId: number) => {
  const URL = baseUrl + `/delivery_permit/${userId}?access_token=${localStorage.getItem('token')}`

  return axios1
    .put(URL, formData, {
        headers: { "Content-Type": "multipart/form-data"}
    })
    .then((response) => {
      console.log(response.data);
      return response;
    })
    .catch((error) => {
      console.log("Error se desio" + error);
    });
};


export const fetchOrderForStatistics = (params: any) => {
    console.log(params)
  let statusArray = params.status.split(",");
  let statusString = "";

  if (params?.status == "ALL") {
    statusString = "";
  } else {
    if (statusArray.length === 1) {
      statusString = `status: '${params.status}' and`;
    } else {
    const substring = statusArray.map((a: any) => `'${a}'`)
      statusString = `status in (${substring.join(", ")}) and`;
    }
  }

  const marketplaceId = localStorage.getItem("marketplaceId");
  const branchId = localStorage.getItem("branchMarketplaceId");

  console.log(marketplaceId, branchId);

  const URL_transactions = `click_and_collect_transaction/all?filter=${statusString} marketplace.id.marketplaceId:${marketplaceId} and marketplace.id.branchMarketplaceId:${branchId}`;
  return axios.get(URL_transactions, {
    params: {
      createdFrom: params.createdFrom,
      createdTo: params.createdTo,
      // "marketplace.id.marketplaceId": marketplaceId,
      // "marketplace.id.branchMarketplaceId": branchMarketplaceId
    },
  });
};
export const fetchPermitsForDriver = (userId?: number, driverId?: number, lbo?: number) => {
  const URL = baseUrl + "/delivery_permit/" + userId + "/" + driverId

  let params = {};

  if (lbo) {
    params = {
      LBO: lbo,
    };
  }
  return axios.get(URL, {
    params: params
  });
};
export const fetchPermitsForStore = (userId?: number, driverId?: number, lbo?: number) => {
  const URL = baseUrl + "/delivery_permit/driver_or_user_therapy/" + userId + "/" + driverId
  let params = {};
  if (lbo) {
    params = {
      LBO: lbo,
    };
  }
  return axios.get(URL, {
    params: params
  });
};

export const fetchOrders = (branchMarketplaceId: number, marketplaceId: number, shouldSendPos:boolean = true): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(orderActions.fetchOrdersStart());
        const URL = '/click_and_collect_order/' + branchMarketplaceId + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                dispatch(orderActions.orders(response.data));

                let ordersForPOS: any = [];

                if (localStorage.getItem("boIpAddress") !== null) {

                    let ordersNotSentToPOS = response.data.filter((ord: any) => {
                         return ord.orderPOSId === null
                    });

                    let counter = ordersNotSentToPOS.length;

                    if(shouldSendPos) {
                    ordersNotSentToPOS.forEach((order: any) => {
                        if (order.orderPOSId === null) {
                            const URL = '/galen_job/' + order.id;
                            axios.get(URL)
                                .then(galen_order_data => {
                                    const config = {
                                        headers: {Authorization: "Bearer " + localStorage.getItem('token')}
                                    };
                                    axios_neutral.post(localStorage.getItem("boIpAddress") + "/order/create", [{...galen_order_data.data}], config)
                                        .then(galen_response => {
                                            ordersForPOS.push({orderPOSId: galen_response.data[0], orderId: order.id});
                                            if (ordersForPOS.length === counter) {
                                                dispatch(setSentToPosFlag(ordersForPOS));
                                            }
                                        })
                                        .catch(error => {
                                            counter--;
                                            if (ordersForPOS.length === counter) {
                                                dispatch(setSentToPosFlag(ordersForPOS));
                                            }
                                            let responseData = {}
                                            if (error.response !== undefined) {
                                                responseData = {
                                                    data: JSON.stringify(error.response.data),
                                                    status: '' + error.response.status + error.response.statusText,
                                                    headers: JSON.stringify(error.response.headers)
                                                }
                                            }
                                            dispatch(saveOrderPOSError([{
                                                error: JSON.stringify(error),
                                                ...responseData,
                                                orderId: +order.id
                                            }]));
                                        })

                                })
                                .catch(error => {
                                    console.log("Error se desio" + error);
                                })
                        }
                    })}
                }
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchOrdersFailed())
            })
    };
}

export const setSentToPosFlag = (orderIds: {orderPOSId:number, orderId: number}[]): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(orderActions.setSentToPosFlagStart());
        const URL = '/click_and_collect_order';
        axios.put(URL, orderIds)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.setSentToPosFlagSuccess(orderIds));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.setSentToPosFlagFailed())
            })
    };
}

export const saveOrderPOSError = (orderPOSError: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch) => {
        dispatch(orderActions.saveOrderPOSErrorStart());
        const URL = '/order_POS_error';
        axios.post(URL, orderPOSError)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.savedPOSError(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.saveOrderPOSErrorFailed())
            })
    };
}

export const processOrderAction = (cncOrderId:number, note:string, willBeReady:number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(orderActions.processOrderStart());
        console.log("Obrada porudzbine");
        const URL = '/click_and_collect_order/' + cncOrderId + "?willBeReadyIn=" + willBeReady + "&note=" + note;
        axios.put(URL)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.processOrder({orderId: cncOrderId, noteForUser:note, responseData: response.data}));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.processOrderFailed())
            })
    };
}
export const processOrderForDriverAction = (cncOrderId:number, userId?:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(orderActions.processOrderStart());
        console.log("Obrada porudzbine");
        const URL = '/click_and_collect_order/delivery_user/' + cncOrderId + "/" + userId;
        axios.put(URL)
            .then(response => {
                console.log(response.data)
                dispatch(
                    fetchAllOrdersForDriverUser()
                )
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.processOrderFailed())
            })
    };
}
export const finishOrderForDriverAction = (cncTransactionId:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(orderActions.processOrderStart());
        console.log("Obrada porudzbine");
        const URL = '/click_and_collect_transaction/delivery_taken/' + cncTransactionId;
        axios.put(URL, {
                status: "TAKEN"
        })
            .then(response => {
                console.log(response.data)
                dispatch(
                    fetchAllOrdersForDriverUser()
                )
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.processOrderFailed())
            })
    };
}


export const preparedOrderAction = (cncOrderId:number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(orderActions.preparedOrderStart());
        console.log("Obrada porudzbine");
        const URL = '/click_and_collect_order/prepared/' + cncOrderId;
        axios.put(URL)
            .then(response => {
                console.log(response.data)
                // todo ovde treba da se promeni status
                dispatch(orderActions.preparedOrder(cncOrderId));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.preparedOrderFailed())
            })
    };
}

export const finishOrderAction = (cncOrderId:number, status:string, note:string, orderPOSId = 0): ThunkAction<void, RootState, unknown, AnyAction> => {

    return dispatch => {
        dispatch(orderActions.finishOrderStart());
        const URL = '/click_and_collect_transaction/' + cncOrderId + "?status=" + status + (status === "CANCELED" || status === "REJECTED" ? "&note=" + note : "");

        if (localStorage.getItem("boIpAddress") !== null && (status === "CANCELED" || status === "REJECTED")) {
            const config = {
                headers: { Authorization: "Bearer " + localStorage.getItem('token') }
            };
            axios_neutral.post( localStorage.getItem("boIpAddress") + "/order/cancel", {id: orderPOSId}, config)
                .then(response =>  {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                    let responseData = {}
                    if (error.response !== undefined) {
                        responseData = {
                            data: JSON.stringify(error.response.data),
                            status: '' + error.response.status + error.response.statusText,
                            headers: JSON.stringify(error.response.headers)
                        }
                    }
                    dispatch(saveOrderPOSError([{
                        error: JSON.stringify(error),
                        ...responseData,
                        orderId: cncOrderId
                    }]));
                })
        }

        axios.post(URL)
            .then(response => {
                console.log(response.data)
                // dispatch(orderActions.finishOrder({cncOrderId: cncOrderId, status: status}));
                status !== "REJECTED" ?
                    dispatch(orderActions.finishOrder({cncOrderId: cncOrderId, status: status, note: note})):
                    dispatch(orderActions.rejectOrder({cncOrderId: cncOrderId, note: note}))
            })
            .catch(error => {
                console.log(error);
                dispatch(orderActions.finishOrderFailed())
            })
    };
}

export const fetchTransactions = (branchMarketplaceId:number, marketplaceId:number): ThunkAction<void, RootState, unknown, AnyAction> =>  {

    return async (dispatch) => {
        dispatch(orderActions.fetchTransactionsStart());
        const URL = '/click_and_collect_transaction/' + marketplaceId  + '/' + branchMarketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.transactions(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchTransactionsFailed())
            })
    };
}

export const fetchAllOrdersForAdminUser = () : ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(orderActions.fetchOrdersStart());
        const URL = '/click_and_collect_order';
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.orders(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchOrdersFailed())
            })
    };
}

export const fetchAllOrdersForDriverUser = () : ThunkAction<void, RootState, unknown, AnyAction> => {
    const userId = localStorage.getItem("id");
    return async (dispatch) => {
        dispatch(orderActions.fetchOrdersStart());
        const URL_orders= '/click_and_collect_order?filter=userAddress is not null';
        const URL_transactions = "/click_and_collect_transaction/all?filter=userDelivery.id:"+ userId;
        // const URL_finishedOrdersForDriver = "/click_and_collect_transaction/all?filter=status:'TAKEN' and userDelivery.id:"+ userId;
        const ordersPromise = axios.get(URL_orders)
        const transactionsPromise = axios.get(URL_transactions)
        // const urlFinishedPromise = axios.get(URL_finishedOrdersForDriver)
            Promise.all([ordersPromise, transactionsPromise]).then(([ordersRes, transactionsRes]: any) => {
                const remapData = transactionsRes?.data.map((transaction:any) => ({
                    ...transaction,
                    id: transaction.orderNumber,
                    transactionId: transaction.id
                }))
                // const remapData2 = finishedRes?.data.map((transaction:any) => ({
                //     ...transaction,
                //     id: transaction.orderNumber,
                //     transactionId: transaction.id
                // }))
                let combinedOrdersArray = [...ordersRes.data, ...remapData]
                combinedOrdersArray.sort((a,b) => a.id - b.id)
                dispatch(orderActions.driverOrders(combinedOrdersArray));
            }).catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchOrdersFailed())
            })
    };
}

export const fetchAllTransactionsForAdminUser = () : ThunkAction<void, RootState, unknown, AnyAction>=> {

    const threeDaysAgo = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm');
    const momentNow = moment().format('YYYY-MM-DDTHH:mm');
    return async (dispatch) => {
        dispatch(orderActions.fetchTransactionsStart());
        const URL = `/click_and_collect_transaction/all?createdFrom=${threeDaysAgo}&createdTo=${momentNow}`;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(orderActions.transactions(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(orderActions.fetchTransactionsFailed())
            })
    };
}