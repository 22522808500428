import React, { useEffect, useState } from "react";
import styles from "./OrderDriverBox.module.scss";
import { useDispatch } from "react-redux";
import { orderActions } from "../../../store/order-slice";
import moment from "moment";
import Button from "../../UI/Buttons/Button/Button";
import ChatBubble from "../../UI/ChatBubble/ChatBubble";
import classNames from "classnames";
import {
  fetchPermitsForDriver,
  finishOrderForDriverAction,
  processOrderForDriverAction,
  uploadDeliveryConsent,
} from "../../../store/order-actions";
import ReactModalWrap from "../../UI/Modals/ReactModalWrap/ReactModalWrap";
import ArrowUpIcon from '@rsuite/icons/ArrowUp';


type Props = {
  order?: any;
  archived?: boolean;
  processed?: boolean;
};

const OrderDriverBox = ({ order, processed, archived }: Props) => {
  let orderBoxData: any;

  orderBoxData = {
    ...order,
    id: order.id,
    date: new Date("" + order.created),
    firstName: order.firstName,
    secondName: order.secondName,
    phoneNumber: order.userPhoneNumberForOrder || order?.userPhoneNumber,
    items: order.items,
    noteForUser: order.noteForUser,
    rfzoTherapyNumber: order.rfzoTherapyNumber,
    userPhoneNumber: order.userPhoneNumber,
    status: order.status,
    totalPrice: order.totalPrice,
    deliveryPrice: order.deliveryPrice,
    totalPriceWithDelivery: order.totalPriceWithDelivery,
    userAddressResponse: order.userAddressResponse,
    marketplaceResponse: order.marketplaceResponse,
  };

  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(orderActions.toggleOrderModal(order));
    setExpanded(true);
  };

  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.orderBox}>
      <div className={styles.cardHeader}>
        <div className={styles.dataAndId}>
          <span className={styles.orderDate}>{moment(orderBoxData.date).format("DD.MM.YYYY")}</span>
          <br />
          <span className={styles.orderId}>#{orderBoxData.id}</span>
        </div>
        <div className={styles.timerAndStatus}>
          {!archived && !processed && (
            <div className={styles.timeCreated}>{moment(orderBoxData.date).format("HH:mm")}</div>
          )}
          {/* {statusMapping[orderBoxData?.status]} */}
          {archived && (
            <>
              {/* {orderBoxData?.status}<br/>  */}
              {statusMapping[orderBoxData?.status]}
            </>
          )}
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.userName}>
          {orderBoxData?.firstName + " " + orderBoxData?.secondName}
          <br />
        </div>
        <div className={styles.rfzoAndPrice}>{archived && orderBoxData?.totalPrice + " RSD"}</div>
      </div>
      {expanded && <DeliveryDetails orderBoxData={orderBoxData} />}
      <div className={styles.pricesWrap}>
        {!(processed && !expanded) && (
          <>
            {" "}
            <div className={styles.priceItem}>
              <span className={styles.label}>Vrednost porudzbine:</span>{" "}
              <span className={styles.value}>{orderBoxData?.totalPrice?.toFixed(2)} RSD</span>
            </div>
            <div className={styles.priceItem}>
              <span className={styles.label}>Cena dostave:</span>{" "}
              <span className={styles.value}>{orderBoxData?.deliveryPrice?.toFixed(2)} RSD</span>
            </div>
          </>
        )}
        <div className={styles.priceItem}>
          <span className={styles.label}>Ukupna cena:</span>{" "}
          <span className={styles.value}>
            <b>{orderBoxData?.totalPriceWithDelivery?.toFixed(2)} RSD</b>
          </span>
        </div>
      </div>
      {expanded && <ProcessButtons order={orderBoxData} />}
      {!expanded && (
        <div className={styles.cardFooter}>
          <Button
            style={{ fontSize: "14px" }}
            onClick={() => {
              showModal();
            }}
            buttonType={archived ? "black" : "blue"}>
            {archived ? "Otvori" : "Obradi"}
          </Button>
        </div>
      )}

      {expanded && (
        <div
          style={{ textAlign: "center", color: "#a9a9a9", fontSize: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (expanded) {
              setExpanded(false);
              dispatch(orderActions.toggleOrderModal(null));
            }
          }}>
          <ArrowUpIcon />
        </div>
      )}
    </div>
  );
};

export default OrderDriverBox;

interface Statuses {
  [key: string]: string;
}

const statusMapping: Statuses = {
  CREATED: "Kreirana",
  REQUESTED: "Zatrazena",
  PREPARED: "Pripremljena",
  PROCESSED: "Obradjena",
  TAKEN: "Preuzeto",
  CANCELED: "Otkazana",
  REQUEST_EXPIRED: "Propušteno",
  PROCESSED_WITHOUT_CONTENT: "Odbijeno",
  REJECT_REQUESTED: "Odbijeno",
};
const DeliveryDetails = ({ orderBoxData }: any) => {
  const { phoneNumber, userAddressResponse, noteForDelivery, marketplaceResponse } = orderBoxData || {};

  const { name, city, number, floor, apartment } = userAddressResponse || {};
  const { name: marketName, address: marketAddress } = marketplaceResponse || {};

  return (
    <div className={styles.deliveryDetailsWrap}>
      <div>
        <span className={styles.detailsLabel}>Tel:</span>
        <a className={styles.phoneNumber} href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
      </div>

      <div className={styles.address}>
        <span className={styles.detailsLabel}>Adresa za isporuku:</span>
        <div>
          <DetailsLink
            link={`https://www.google.com/maps/dir/?api=1&destination=${orderBoxData?.userAddressResponse?.latitude},${orderBoxData?.userAddressResponse?.longitude}`}
            className={styles.deliveryAddress}
            label={`${name} ${number}, ${city}`}
          />
          <br />
          Sprat: {floor}, Stan: {apartment}
        </div>
      </div>

      <div className={styles.deliveryNote}>
        <span className={classNames(styles.detailsLabel, styles.deliveryNoteLabel)}>Dodatne napomene:</span>
        <ChatBubble noteFromUserFlag>
          {noteForDelivery ? (
            noteForDelivery
          ) : (
            <span className={styles.NoNoteFromUser}>Korisnik nije uneo napomenu</span>
          )}
        </ChatBubble>
      </div>

      <div className={styles.marketPlaceDetails}>
        <div>
          <span className={styles.detailsLabel}>Podaci o apoteci:</span>
          <div className={styles.deliveryAddress}>{marketName}</div>
        </div>
        <div>
          <span className={styles.detailsLabel}>Adresa:</span>
          <DetailsLink
            className={styles.deliveryAddress}
            link={`https://www.google.com/maps/dir/?api=1&destination=${orderBoxData?.marketplaceResponse?.latitude},${orderBoxData?.marketplaceResponse?.longitude}`}
            label={marketAddress}
          />
        </div>
      </div>
    </div>
  );
};

const DetailsLink = ({ className, link, label }: any) => {
  return (
    <a target="_blank" rel="noreferer" href={link} className={className}>
      {label}
    </a>
  );
};

const ProcessButtons = ({ order }: any) => {
  const dispatch = useDispatch();
  const [unsignedPermits, setUnsignedPermits] = useState<any>([]);
  const userIdFromLs = localStorage.getItem("id");
  const userId = userIdFromLs ? JSON.parse(userIdFromLs) : null;

  const acceptOrderAsDriver = (userId?: number) => {
    dispatch<any>(processOrderForDriverAction(order.id, userId));
  };

  const finishOrderAsDriver = () => {
    if (order?.transactionId) {
      dispatch<any>(finishOrderForDriverAction(order.transactionId));
    }
  };

  useEffect(() => {
    if (order?.userDeliveryResponse) {
      fetchOrderPermits();
    }
  }, [order?.userDeliveryResponse]);

  const fetchOrderPermits = () => {
    fetchPermitsForDriver(order?.userResponse?.id, order?.userDeliveryResponse?.id, order?.userLBO).then((res) =>
      setUnsignedPermits(res?.data)
    );
  };

  return (
    <>
      {order.userDeliveryResponse == null ? (
        <>
          <Button buttonType="blue" onClick={() => acceptOrderAsDriver(userId)}>
            Prihvati porudžbinu
          </Button>
        </>
      ) : (
        <>
          {!order?.userResponse?.deliveryPermit && (
            <PermitsUploadWrap reFetchPermits={fetchOrderPermits} unsignedPermits={unsignedPermits} order={order} />
          )}
          <Button buttonType="blue" onClick={() => finishOrderAsDriver()}>
            Dostavljeno
          </Button>
        </>
      )}
    </>
  );
};

export const PermitsUploadWrap = ({ unsignedPermits, order, reFetchPermits }: any) => {
  console.log(unsignedPermits);
  if (unsignedPermits?.length > 0) {
    return (
      <div className={styles.permitsUploadWrap}>
        {unsignedPermits?.map((permit: any, key: number) => (
          <PermitUpload key={key} reFetchPermits={reFetchPermits} permit={permit} order={order} />
        ))}
      </div>
    );
  }

  return null;
};

export const PermitUpload = ({ order, permit, reFetchPermits }: any) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileUpload = (e: any) => {
    const renamedFile = renameFile(e.target.files[0], `p${permit.id}`);
    setFile(renamedFile);
  };

  const submitUpload = () => {
    console.log("fired");
    const formData = new FormData();
    if (file !== null) {
      formData.append("images", file);
    }
    uploadDeliveryConsent(formData, permit.id).then((res) => reFetchPermits());
    setFile(null)
  };

  let permitHref = "";
  let permitLabel = "";
  if (permit?.signed) {
    permitHref = process.env.REACT_APP_CDN_PATH + "user_delivery_permits/" + permit.signedPermitFileName;
    permitLabel = file ? file?.name : permit?.signedPermitFileName + " (kliknite za izmenu)";
  } else {
    permitLabel = "Izaberi " + permit?.deliveryPermitType;
    permitHref = process.env.REACT_APP_CDN_PATH + "delivery_permits/p" + permit.id + ".pdf";
  }

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  return (
    <>
      <ConfirmModal
        isOpen={confirmModalOpen}
        setIsOpen={setConfirmModalOpen}
        confirm={() => {
          setConfirmModalOpen(false);

          submitUpload();
        }}
      />
      <div className={styles.permitUploadWrap}>
        <a className={styles.permitLink} target="_blank" rel="noreferrer" href={permitHref}>
          Preuzmite {permit?.signed ? "potpisani" : "nepotpisan"} PDF
        </a>
        <div className={styles.permitInputWrap}>
          <label htmlFor="file" className={styles.fileLabel}>
            <span className={styles.fileLabelText}>{permitLabel}</span>
          </label>
          <input id="file" className={styles.permitInput} onChange={(e) => handleFileUpload(e)} type="file" />
        </div>
        {
          <Button
            disable={!file}
            className={styles.uploadButton}
            disabled={!file}
            onClick={() => {
              permit?.signed ? setConfirmModalOpen(true) : submitUpload();
            }}
            buttonType="blue">
            Pošalji
          </Button>
        }
      </div>
    </>
  );
};

const renameFile = (file: File, newName: string) => {
  const fileExtension = file.name.split(".").pop();
  const newFileName = `${newName}.${fileExtension}`;
  return new File([file], newFileName, { type: file.type });
};

const ConfirmModal = ({ isOpen, setIsOpen, confirm }: any) => {
  return (
    <ReactModalWrap
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
      }}>
      <div className={styles.confirmModalContent}>
        <div className={styles.header}>Da li ste sigurni da zelite da dodate novi fajl</div>

        <Button onClick={() => confirm()} buttonType="blue">
          DA
        </Button>
        <Button onClick={() => setIsOpen(false)} buttonType="white">
          NE
        </Button>
      </div>
    </ReactModalWrap>
  );
};
