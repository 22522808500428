import React from 'react';
import styles from './Navbar.module.scss';
import {Link, useMatch, useResolvedPath} from "react-router-dom";

const Navbar = () => {
    return (
        <nav className={styles.Navbar}>
            <ul style={{marginBottom: "10px"}}>
                <CustomLink to="/order-processing">Pregled porudzbina</CustomLink>
                <CustomLink to="/articles-outlet">Podešavanje outlet-a</CustomLink>
                <CustomLink to="/articles-visibility">Podešavanje vidljivosti artikala</CustomLink>
                <CustomLink to="/mobile-commercials">Mobile reklame</CustomLink>
                <CustomLink to="/web-commercials">Web reklame</CustomLink>
                <CustomLink to="/rfzo-patient-reminder">RFZO podsetnik - pacijenti</CustomLink>
                <CustomLink to="/rfzo-article-reminder">RFZO podsetnik - artikli</CustomLink>
                <CustomLink to="/push-notifications">Slanje push notifikacija</CustomLink>
            </ul>

        </nav>
    );
};

export default Navbar;

// @ts-ignore
function CustomLink({to, children, ...props}) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    return (
        <li className={isActive ? styles.active : ""}>
            <Link to={to} {...props} >
                {children}
            </Link>
        </li>
    )
}
